<template>
    <div id="illustration">
        <fa id="card1" :icon="['fas', 'credit-card']"/>
        +
        <fa id="card2" :icon="['fas', 'credit-card']"/>
    </div>
</template>

<style scoped>
#card1 {
    color: blue;
}
#card2 {
    color: red;
}
</style>